import * as React from 'react';
import { graphql } from 'gatsby';

import { useLanguageNavigateEffect } from '~/hooks/useLanguageNavigateEffect';

import type { PageProps } from 'gatsby';

type NotFoundPageProps = PageProps<GatsbyTypes.NotFoundPageQuery>;

const NotFoundPage: React.FC<NotFoundPageProps> = ({ data }) => {
  const languages = data
    .allStrapiPolicyMain
    .nodes
    .map((node) => node.language)
    .filter(Boolean) as string[];

  useLanguageNavigateEffect(languages);
  return null;
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundPage {
    allStrapiPolicyMain {
      nodes {
        language
      }
    }
  }
`;
